import { CustomDataSource } from 'cesium'
import { Asset } from '../Asset'

export interface JsdcDataSourceConstructorOptions {
  asset: Asset
}

class JsdcDataSource extends CustomDataSource {
  _asset: Asset
  constructor ({ asset }: JsdcDataSourceConstructorOptions) {
    super(asset.name)
    this._asset = asset
  }
}

export default JsdcDataSource
