/* eslint-disable */
import './index.scss'
import { PanelContext, IPanelContext } from '../../context'

export interface IExpandContainer extends IPanelContext {
  children: React.ReactNode
}

const ExpandContainer = (props: IExpandContainer) => {
  return (
    <PanelContext.Provider value={props}>
      <div className='ExpandContainer'>
        {props.children}
      </div>
    </PanelContext.Provider>
  )
}

export default ExpandContainer
