/* eslint-disable */
import { useContext, useEffect } from 'react'
import {
  Viewer as CesiumViewer
} from 'cesium'
import * as Cesium from 'cesium'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { SpatialQueryTypeContext } from '../QueryPanel'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import { JsdcContext } from '../../components/JsdcViewer'

export interface ISketch {
  bufferRadius: number
  disabled: boolean
  onRadiusChange: (value: number) => void
}

const Sketch = ({
  bufferRadius,
  disabled,
  onRadiusChange
}: ISketch) => {
  const context = useContext(SpatialQueryTypeContext)
  const jsdc = useContext(JsdcContext)

  const clearPoint = async () => {
    // const viewer = viewerRef.current?.cesiumElement
    // if (!viewer) return
    const viewer = await jsdc.asyncViewer
    viewer.entities.removeById('sketch_point')
    viewer.entities.removeById('sketch_buffer')
  }

  const updateBuffer = async () => {
    // const viewer = viewerRef.current?.cesiumElement
    // if (!viewer) return
    const viewer = await jsdc.asyncViewer
    const buffer = viewer.entities.getById('sketch_buffer')
    if (!buffer || !buffer.ellipse) return
    buffer.ellipse = new Cesium.EllipseGraphics({
      semiMinorAxis: bufferRadius * 1000,
      semiMajorAxis: bufferRadius * 1000,
      material: Cesium.Color.fromBytes(255, 162, 0, 80),
      outline: true, // height must be set for outline to display
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
    })
  }

  const createPoint = async (position: Cesium.Cartesian3) => {
    // const viewer = viewerRef.current?.cesiumElement
    // if (!viewer) return
    const viewer = await jsdc.asyncViewer
    const pinBuilder = new Cesium.PinBuilder()
    viewer.entities.add({
      id: 'sketch_point',
      position: position,
      billboard: {
        image: '/icon/location-search-icon.svg',
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      }
    })
    viewer.entities.add({
      id: 'sketch_buffer',
      position: position,
      ellipse: {
        semiMinorAxis: bufferRadius * 1000,
        semiMajorAxis: bufferRadius * 1000,
        material: Cesium.Color.fromBytes(255, 162, 0, 80),
        outline: true, // height must be set for outline to display
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      },
    })
  }

  const initSketch = async () => {
    clearPoint()
    const elementToChange = document.getElementsByTagName("body")[0]
    elementToChange.style.cursor = 'crosshair'
    // const viewer = viewerRef.current?.cesiumElement
    // if (!viewer) return
    const viewer = await jsdc.asyncViewer
    viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    )
    const handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)
    handler.setInputAction((event: any) => {
      const earthPosition = viewer.scene.pickPosition(event.position)
      elementToChange.style.cursor = "default"
      createPoint(earthPosition)
      handler.destroy()
      console.log('create')
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
  }

  const showBuffer = async (show: boolean) => {
    // const viewer = viewerRef.current?.cesiumElement
    // if (!viewer) return
    const viewer = await jsdc.asyncViewer
    const center = viewer.entities.getById('sketch_point')
    const circle = viewer.entities.getById('sketch_buffer')
    if (!center || !circle) return
    center.show = show
    circle.show = show
  }

  const showTownShapes = (show: boolean) => {
    if (!show) return
    // const viewer = viewerRef.current?.cesiumElement
    // if (!viewer) return

    // viewer.dataSources.add(Cesium.GeoJsonDataSource.load(townShapes, {
    //   fill: Cesium.Color.fromBytes(255, 162, 0, 80),
    //   strokeWidth: 2,
    //   stroke: Cesium.Color.fromBytes(0, 0, 0, 80)
    // }));
  }

  useEffect(() => {
    updateBuffer()
  }, [bufferRadius])

  useEffect(() => {
    showBuffer(context.spatialQueryType === 'buffer')
    showTownShapes(context.spatialQueryType === 'town')
  }, [context.spatialQueryType])

  return (
    <>
      <Grid item xs={5}>
        <Button
          variant='outlined'
          onClick={initSketch}
          startIcon={<AddCircleOutlineIcon />}
          fullWidth
          sx={{ height: '40px' }}
          disabled={disabled}
        >定位查詢點</Button>
      </Grid>
      <Grid item xs={5}>
        <OutlinedInput
          size='small'
          type='number'
          value={bufferRadius}
          endAdornment={<InputAdornment position="end">公里</InputAdornment>}
          onChange={(e) => {
            onRadiusChange(Number(e.target.value))
          }}
          disabled={disabled}
        />
      </Grid>
    </>
  )
}

export default Sketch
