/* eslint-disable */
import Divider from '@mui/material/Divider'
import Slider from '@mui/material/Slider'
import Sketch from '../../bigTreeCompnt/Sketch'
import { PanelContext } from '../../context'
import { useContext, useState, useEffect, createContext } from 'react'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import MultiChipSelect from '../MultiChipSelect'
import { SpatialQueryType } from '../../hooks'
import geoJsonTrees from '../../assets/trees.json'
import adminArea from '../../assets/tw-towns.json'
import { without } from 'lodash'
import { JsdcContext } from '../../components/JsdcViewer'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import { SelectChangeEvent } from '@mui/material/Select'
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete'
import './index.css'
import { Typography } from '@mui/material'

const treeIds = geoJsonTrees.features.map((t, index) => {
  return t.properties['名稱']
})

export const SpatialQueryTypeContext = createContext<{
  spatialQueryType: SpatialQueryType
}>({ spatialQueryType: 'buffer' })

interface ITWTown {
  zip: string
  name: string
}

interface ITWCounty {
  districts: ITWTown[]
  name: string
}

const QueryPanel = () => {
  const [spatialQueryType, setspatialQueryType] = useState<SpatialQueryType>('none')
  const [area] = useState<ITWCounty[]>(adminArea)
  const [areaTown, setareaTown] = useState<string[]>([])
  const states = useContext(PanelContext)
  const jsdc = useContext(JsdcContext)

  useEffect(() => {
    const selectedCounty = area.filter(a => a.name === county).at(0)
    if (!selectedCounty) return
    const townsByCounty = selectedCounty.districts.map(t => t.name)
    setareaTown(townsByCounty)
  }, [states?.county])

  if (!states) return <></>
  const {
    heightRange, bufferRadius, county, towns, treeId, queryType,
    onHeightChange, onBufferChange, onCountyChange,
    onTreeIdChange, onTownsChange, onQuery, onQueryTypeChange,
    onResultTreeIdsChange
  } = states

  const handleReset = async () => {
    const viewer = await jsdc.asyncViewer
    viewer.entities.removeById('sketch_point')
    viewer.entities.removeById('sketch_buffer')
    const dataSource = viewer.dataSources.getByName('treesDatasource')[0]
    for (let i = 0; i < dataSource.entities.values.length; i++) {
      const entity = dataSource.entities.values[i]
      entity.show = true
    }
    onResultTreeIdsChange([])
    onBufferChange(30)

    onCountyChange('')
    onTownsChange([])
    onHeightChange([60, 85])
    onTreeIdChange('')
  }

  const renderDivider = () => {
    return (
      <Grid item xs={12}>
        <Divider sx={{ width: '100%' }} />
      </Grid>
    )
  }

  const renderSpatialQuery = () => {
    return (
      <>
        <SpatialQueryTypeContext.Provider
          value={{
            spatialQueryType: spatialQueryType
          }}
        >
          <Grid item xs={2}>
            <Radio
              sx={{ color: '#00489A' }}
              checked={spatialQueryType === 'none'}
              onClick={() => {
                setspatialQueryType('none')
                onQueryTypeChange('none')
              }}
            />
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>無空間查詢</Typography>
          </Grid>


          <Grid item xs={2}>
            <Radio
              sx={{ color: '#00489A' }}
              checked={spatialQueryType === 'buffer'}
              onClick={() => {
                setspatialQueryType('buffer')
                onQueryTypeChange('buffer')
              }}
            />
          </Grid>
          <Sketch
            bufferRadius={bufferRadius}
            disabled={spatialQueryType !== 'buffer'}
            onRadiusChange={onBufferChange}
          />
          <Grid item xs={2}>
            <Radio
              sx={{ color: '#00489A' }}
              checked={spatialQueryType === 'town'}
              onClick={() => {
                setspatialQueryType('town')
                onQueryTypeChange('town')
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl size="small" fullWidth>
              <InputLabel id="county-select-small">縣市</InputLabel>
              <Select
                labelId="county-select-small"
                id="county-select-small"
                value={county}
                label="縣市"
                onChange={(event: SelectChangeEvent) => {
                  onCountyChange(event.target.value)
                }}
                disabled={spatialQueryType !== 'town'}
              >
                {area.map((a, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={a.name}
                    >{a.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <MultiChipSelect
              placeholder='鄉鎮'
              towns={towns}
              townsSet={areaTown}
              isUrbanUsageFiltered={spatialQueryType === 'town'}
              isUrbanUsageFosced={spatialQueryType === 'town'}
              onChange={(value) => {
                onTownsChange(value)
              }}
              onRemove={(e, value) => {
                e.preventDefault()
                onTownsChange(without(towns, value))
              }}
            />
          </Grid>
        </SpatialQueryTypeContext.Provider>
      </>
    )
  }

  const renderAttributeQuery = () => {
    return (
      <>
        <Grid item xs={2}>
          <Checkbox
            sx={{ color: '#00489A' }}
            checked={states.isHeightRangeIncluded}
            onClick={(e) => {
              // @ts-ignore
              states.onHeightRangeInclude(e.target.checked)
            }}
          />
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <span>樹高</span>
          <span>(公尺)</span>
        </Grid>
        <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
          <Slider
            value={heightRange}
            onChange={(event: Event, newValue: number | number[]) => {
              onHeightChange(newValue)
            }}
            valueLabelDisplay='on'
            valueLabelFormat={(value) => {
              return `${value}`
            }}
            step={1}
            min={60}
            max={85}
            disabled={!states.isHeightRangeIncluded}
            sx={{ color: '#00489A' }}
          />
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            sx={{ color: '#00489A' }}
            checked={states.isTreeIdIncluded}
            onClick={(e) => {
              // @ts-ignore
              states.onTreeIdIncluded(e.target.checked)
            }}
          />
        </Grid>
        <Grid item xs={10}>
          <Autocomplete
            size='small'
            disablePortal
            options={treeIds}
            value={treeId}
            renderInput={(params) => <TextField {...params} label="巨木名稱" />}
            disabled={!states.isTreeIdIncluded}
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              value: string | null,
              reason: AutocompleteChangeReason,
              details?: AutocompleteChangeDetails<string> | undefined
            ) => {
              onTreeIdChange(event.currentTarget.innerHTML)
            }}
          />
        </Grid>
      </>
    )
  }

  const renderAction = () => {
    return (
      <>
        <Grid item xs={6}>
          <Button
            color='error'
            variant='contained'
            startIcon={<ClearAllIcon />}
            onClick={handleReset}
            sx={{ backgroundColor: '#B0AFAF' }}
            fullWidth
          >清除</Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant='contained'
            startIcon={<SearchIcon />}
            onClick={async () => {
              const viewer = await jsdc.asyncViewer
              onQuery(queryType, viewer)
            }}
            sx={{ backgroundColor: '#00489A' }}
            fullWidth
          >查詢</Button>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container spacing={2}>
        {renderAttributeQuery()}
        {renderDivider()}
        {renderSpatialQuery()}
        {renderDivider()}
        {renderAction()}
      </Grid>
    </>
  )
}

export default QueryPanel
