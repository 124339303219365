/* eslint-disable */
import { useState } from 'react'
import {
  Entity as CesiumEntity
} from 'cesium'
import * as turf from '@turf/turf'
import * as Cesium from 'cesium'
import { Cartesian3ToDegree } from '../util/Cartesian3ToDegree'
import geoJsonTrees from '../assets/trees.json'
import { ExpandType } from '../bigTreeCompnt/ExpandContainer/Expand'
// import townShapes from '../assets/tw-towns-shape.json'

export type SpatialQueryType = 'buffer' | 'town' | 'none'

export const usePanelStates = () => {
  const [queryType, setqueryType] = useState<SpatialQueryType>('none')
  const [heightRange, setheightRange] = useState<number[]>([60, 85])
  const [bufferRadius, setbufferRadius] = useState<number>(30)
  const [county, setcounty] = useState<string>('')
  const [towns, settowns] = useState<string[]>([])
  const [treeId, settreeId] = useState<string>('')
  const [selectedExpand, setselectedExpand] = useState<ExpandType>('close')
  const [resultTreeIds, setresultTreeIds] = useState<string[]>([])
  const [isHeightRangeIncluded, setisHeightRangeIncluded] = useState<boolean>(true)
  const [isTreeIdIncluded, setisTreeIdIncluded] = useState<boolean>(false)

  const onHeightRangeInclude = (value: boolean) => {
    setisHeightRangeIncluded(value)
  }

  const onTreeIdIncluded = (value: boolean) => {
    setisTreeIdIncluded(value)
  }

  const onQueryTypeChange = (queryType: SpatialQueryType) => {
    setqueryType(queryType)
  }

  const onResultTreeIdsChange = (value: string[]) => {
    setresultTreeIds(value)
  }

  const onExpandClick = (expandType: ExpandType) => {
    setselectedExpand(expandType)
  }

  const onTreeIdChange = (value: string) => {
    settreeId(value)
  }

  const onCountyChange = (value: string) => {
    setcounty(value)
    // settowns([])
  }

  const onTownsChange = (value: string[]) => {
    settowns(value)
  }

  const onHeightChange = (newValue: number | number[]) => {
    setheightRange(newValue as number[])
  }

  const onBufferChange = (value: number) => {
    setbufferRadius(value)
  }

  const getEntityCenterDegree = (center: CesiumEntity) => {
    if (!center.position) return
    const centerPosition: Cesium.Cartesian3 | undefined = undefined
    const julian = Cesium.JulianDate.now()
    if (!julian) return
    const position = center.position.getValue(julian, centerPosition)
    const centerCoordinate = Cartesian3ToDegree(position)
    return centerCoordinate
  }

  const isHeightInRange = (tree: CesiumEntity): boolean => {
    const id = tree.id
    const inRange = geoJsonTrees.features.filter(t => t.properties['樹高_LiDAR'] >= heightRange[0] &&
      t.properties['樹高_LiDAR'] <= heightRange[1] &&
      t.properties['OBJECTID'] === Number(id)
    )
    if (inRange.length !== 0) return true
    return false
  }

  const onQuery = (queryType: SpatialQueryType, viewer: Cesium.Viewer) => {

    const treeDatasource = viewer.dataSources.getByName('treesDatasource')[0]

    if (queryType === 'town') {

      let result: any[] = [];

      if (towns.length === 0) {
        result = geoJsonTrees.features.filter(t =>
          t.properties.COUNTYNAME === county
        )
      } else {
        result = geoJsonTrees.features.filter(t =>
          t.properties.COUNTYNAME === county &&
          towns.includes(t.properties.TOWNNAME)
        )
      }



      if (isHeightRangeIncluded) {
        result = result.filter(t =>
          t.properties['樹高_LiDAR'] >= heightRange[0] &&
          t.properties['樹高_LiDAR'] <= heightRange[1]
        )
      }
      if (isTreeIdIncluded) {
        result = result.filter(t =>
          t.properties['名稱'] === treeId
        )
      }

      const resultIds: string[] = result.map(t => t.properties['OBJECTID'].toString())
      setresultTreeIds(resultIds)
      setselectedExpand('result')
      const treeDatasource = viewer.dataSources.getByName('treesDatasource')[0]
      for (let i = 0; i < treeDatasource.entities.values.length; i++) {
        const entity = treeDatasource.entities.values[i]
        const modifiableEntity = treeDatasource.entities.getById(entity.id)
        if (!modifiableEntity) continue
        if (
          resultIds.includes(modifiableEntity.id.toString()) &&
          isHeightInRange(modifiableEntity)
        ) {
          modifiableEntity.show = true
          continue
        }
        modifiableEntity.show = false
      }
      return
    }

    if (queryType === 'none') {
      // alert('wwww')
      const resultIds: string[] = []
      for (let i = 0; i < treeDatasource.entities.values.length; i++) {
        const entity = treeDatasource.entities.values[i]
        const entityId = entity.id.toString()
        if (
          entityId.includes('sketch_point') ||
          entityId.includes('sketch_buffer')
        ) continue
        const modifiableEntity = treeDatasource.entities.getById(entity.id)
        if (!modifiableEntity) continue
        const id = modifiableEntity.id
        let result: any[] = geoJsonTrees.features.filter(t =>
          t.properties['OBJECTID'] === Number(id)
        )
        if (isHeightRangeIncluded) {
          result = result.filter(t =>
            t.properties['樹高_LiDAR'] >= heightRange[0] &&
            t.properties['樹高_LiDAR'] <= heightRange[1]
          )
        }
        if (isTreeIdIncluded) {
          result = result.filter(t =>
            t.properties['名稱'] === treeId
          )
        }
        if (result.length !== 0) {
          modifiableEntity.show = true
          resultIds.push(modifiableEntity.id.toString())
          continue
        }
        modifiableEntity.show = false
      }
      setresultTreeIds(resultIds)
      setselectedExpand('result')
      return
    }

    const center = viewer.entities.getById('sketch_point')
    const buffer = viewer.entities.getById('sketch_buffer')
    if (!buffer || !center || !center.position) {
      alert('請選擇定位點')
      return
    }
    const centerCoordinate = getEntityCenterDegree(center)
    if (!centerCoordinate) return
    const bufferPolygon = turf.circle(centerCoordinate, bufferRadius)
    const resultIds: string[] = []
    for (let i = 0; i < treeDatasource.entities.values.length; i++) {
      const entity = treeDatasource.entities.values[i]
      const entityId = entity.id.toString()
      if (
        entityId.includes('sketch_point') ||
        entityId.includes('sketch_buffer')
      ) continue
      const treeCenter = getEntityCenterDegree(entity)
      if (!treeCenter) continue
      const treeCenterPoint = turf.point(treeCenter)
      const isInBuffer = turf.booleanPointInPolygon(treeCenterPoint, bufferPolygon)
      const modifiableEntity = treeDatasource.entities.getById(entity.id)
      if (!modifiableEntity) continue
      if (isInBuffer) {
        const id = modifiableEntity.id
        let result: any[] = geoJsonTrees.features.filter(t =>
          t.properties['OBJECTID'] === Number(id)
        )
        if (isHeightRangeIncluded) {
          result = result.filter(t =>
            t.properties['樹高_LiDAR'] >= heightRange[0] &&
            t.properties['樹高_LiDAR'] <= heightRange[1]
          )
        }
        if (isTreeIdIncluded) {
          result = result.filter(t =>
            t.properties['名稱'] === treeId
          )
        }
        if (result.length !== 0) {
          modifiableEntity.show = true
          resultIds.push(modifiableEntity.id.toString())
          continue
        }
      }
      modifiableEntity.show = false
    }
    setresultTreeIds(resultIds)
    setselectedExpand('result')
  }

  return {
    heightRange,
    bufferRadius,
    county,
    towns,
    treeId,
    selectedExpand,
    resultTreeIds,
    queryType,
    onHeightChange,
    onBufferChange,
    onCountyChange,
    onTownsChange,
    onTreeIdChange,
    onExpandClick,
    onResultTreeIdsChange,
    onQuery,
    onQueryTypeChange,

    onTreeIdIncluded, onHeightRangeInclude, isHeightRangeIncluded, isTreeIdIncluded
  }
}