/* eslint-disable */
import { Ion, Viewer } from 'cesium'
import AssetLoader from '../AssetLoader'
import InfoBoxController from '../Controller/InfoBoxController'
import TimeController from '../Controller/TimeController'
import ViewerTask from '../ViewerTask'
import LayerController from '../Controller/LayerController'
import * as types from './types'
import * as Cesium from 'cesium'
// import CesiumNavigation from 'cesium-navigation-es6'
import CesiumNavigation from './CesiumNavigation'

const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MjM5NTU2ZC1iYjY4LTQ1ZDYtYmIxMS02MmJjNzRkZjNjNzMiLCJpZCI6MzA1NTYsImlhdCI6MTY2NzU0NjM5OX0.p7CMV6F-p-0g54VLUMM55o7_Nfv-dZ0GGpPUvnvLfEY'

export const JsdcEntityTypeMap = {
  controlPoint: 'controlPoint'
}

export type JsdcEntityType = keyof typeof JsdcEntityTypeMap

class JSDC {
  viewer: Viewer | undefined
  viewerTask: ViewerTask = new ViewerTask()
  InfoBoxController: InfoBoxController | undefined
  TimeController: TimeController | undefined
  LayerController: LayerController
  AssetLoader: AssetLoader | undefined
  constructor() {
    Ion.defaultAccessToken = TOKEN
    this.LayerController = new LayerController(this.asyncViewer)
  }

  get asyncViewer () {
    return new Promise<Viewer>(resolve => this.viewerTask.addTask(viewer => resolve(viewer)))
  }

  createViewer (id: string, options: Viewer.ConstructorOptions = {}) {
    options.imageryProviderViewModels = [
      new Cesium.ProviderViewModel({
        name: '臺灣通用正射影像圖',
        tooltip: '臺灣通用正射影像圖',
        iconUrl: '/icon/basemap/map-0.png',
        creationFunction: () => {
          return new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://wmts.nlsc.gov.tw/wmts/PHOTO2/default/GoogleMapsCompatible/{TileMatrix}/{TileRow}/{TileCol}',
            layer: '臺灣通用正射影像圖',
            style: 'default',
            format: 'image/jpeg',
            tileMatrixSetID: 'default028mm',
          })
        }
      }),
      new Cesium.ProviderViewModel({
        name: '臺灣通用電子地圖',
        tooltip: '臺灣通用電子地圖',
        iconUrl: '/icon/basemap/map-1.png',
        creationFunction: () => {
          return new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://wmts.nlsc.gov.tw/wmts/EMAP5/default/GoogleMapsCompatible/{TileMatrix}/{TileRow}/{TileCol}',
            layer: '臺灣通用電子地圖',
            style: 'default',
            format: 'image/jpeg',
            tileMatrixSetID: 'default028mm',
          })
        }
      }),
      new Cesium.ProviderViewModel({
        name: '1904-日治臺灣堡圖-1:20,000',
        tooltip: '1904-日治臺灣堡圖-1:20,000',
        iconUrl: '/icon/basemap/map-2.png',
        creationFunction: () => {
          return new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://gis.sinica.edu.tw/tileserver/file-exists.php?img=JM20K_1904-jpg-{TileMatrix}-{TileCol}-{TileRow}',
            layer: '1904-日治臺灣堡圖-1:20,000',
            style: 'default',
            format: 'image/jpeg',
            tileMatrixSetID: 'default028mm',
          })
        }
      }),
      new Cesium.ProviderViewModel({
        name: '1916-蕃地地形圖-1：50000',
        tooltip: '1916-蕃地地形圖-1：50000',
        iconUrl: '/icon/basemap/map-3.png',
        creationFunction: () => {
          return new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://gis.sinica.edu.tw/tileserver/file-exists.php?img=JM50K_1916-jpg-{TileMatrix}-{TileCol}-{TileRow}',
            layer: '1916-蕃地地形圖-1：50000',
            style: 'default',
            format: 'image/jpeg',
            tileMatrixSetID: 'default028mm',
          })
        }
      }),
      new Cesium.ProviderViewModel({
        name: '1921-日治地形圖-1:25,000',
        tooltip: '1921-日治地形圖-1:25,000',
        iconUrl: '/icon/basemap/map-3.png',
        creationFunction: () => {
          return new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://gis.sinica.edu.tw/tileserver/file-exists.php?img=JM25K_1921-png-{TileMatrix}-{TileCol}-{TileRow}',
            layer: '1921-日治地形圖-1:25,000',
            style: 'default',
            format: 'image/jpeg',
            tileMatrixSetID: 'default028mm',
          })
        }
      }),
      new Cesium.ProviderViewModel({
        name: '1989-經建1版地形圖-1:25,000',
        tooltip: '1989-經建1版地形圖-1:25,000',
        iconUrl: '/icon/basemap/map-4.png',
        creationFunction: () => {
          return new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://gis.sinica.edu.tw/tileserver/file-exists.php?img=TM25K_1989-jpg-{TileMatrix}-{TileCol}-{TileRow}',
            layer: '1989-經建1版地形圖-1:25,000',
            style: 'default',
            format: 'image/jpeg',
            tileMatrixSetID: 'default028mm',
          })
        }
      }),
      new Cesium.ProviderViewModel({
        name: '1993-經建2版地形圖-1:25,000',
        tooltip: '1993-經建2版地形圖-1:25,000',
        iconUrl: '/icon/basemap/map-5.png',
        creationFunction: () => {
          return new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://gis.sinica.edu.tw/tileserver/file-exists.php?img=TM25K_1993-jpg-{TileMatrix}-{TileCol}-{TileRow}',
            layer: '1993-經建2版地形圖-1:25,000',
            style: 'default',
            format: 'image/jpeg',
            tileMatrixSetID: 'default028mm',
          })
        }
      }),
      new Cesium.ProviderViewModel({
        name: '2001-經建3版地形圖-1:25,000',
        tooltip: '2001-經建3版地形圖-1:25,000',
        iconUrl: '/icon/basemap/map-6.png',
        creationFunction: () => {
          return new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://gis.sinica.edu.tw/tileserver/file-exists.php?img=TM25K_2001-jpg-{TileMatrix}-{TileCol}-{TileRow}',
            layer: '2001-經建3版地形圖-1:25,000',
            style: 'default',
            format: 'image/jpeg',
            tileMatrixSetID: 'default028mm',
          })
        }
      })
    ]



    // 

    options.terrainProviderViewModels = []
    const modifiedOptions = {
      ...options,
      animation: false,
      // projectionPicker: false,
      // navigationHelpButton: false,
      // sceneModePicker: false,
      // geocoder: false,
      // baseLayerPicker: false,
      // homeButton: false,
      fullscreenButton: false,
      imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
        url: 'https://wmts.nlsc.gov.tw/wmts/PHOTO2/default/GoogleMapsCompatible/{TileMatrix}/{TileRow}/{TileCol}',
        layer: '臺灣通用正射影像圖',
        style: 'default',
        format: 'image/jpeg',
        tileMatrixSetID: 'default028mm',
      })
    }
    /* istanbul ignore next */
    const viewer = new Viewer(id, modifiedOptions)

    const mixinOptions: any = {}

    const cart = Cesium.Cartographic.fromCartesian(
      new Cesium.Cartesian3(
        -3234837.395982461,
        5084370.488937034,
        2353821.538332212
      )
    )
    mixinOptions.defaultResetView = cart
    //相机方向
    mixinOptions.orientation = {
      heading: 5.6,
      pitch: -0.40005092039086687
    }

    mixinOptions.enableCompass = true
    mixinOptions.enableZoomControls = true
    mixinOptions.enableDistanceLegend = true
    mixinOptions.enableCompassOuterRing = true
    mixinOptions.resetTooltip = "重置"
    mixinOptions.zoomInTooltip = "放大"
    mixinOptions.zoomOutTooltip = "縮小"
    new CesiumNavigation(viewer, mixinOptions)

    this.viewer = viewer
    const s = (viewer.scene as any)
    s._creditContainer.style.display = 'none'

    this.TimeController = new TimeController(viewer)
    this.InfoBoxController = new InfoBoxController(viewer)
    this.AssetLoader = new AssetLoader(viewer)
    this.viewerTask.setViewer(viewer)

    return viewer
  }
}

export default new JSDC()
export { types }
