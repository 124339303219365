import { Color, ConstantProperty, Entity, GeoJsonDataSource, HeightReference, PolygonGraphics, PropertyBag } from 'cesium'
import Cartesian3 from 'cesium/Source/Core/Cartesian3'
import { random } from 'lodash'
import { UID } from '../../../utils'
import { Asset } from '../../Asset'
import JsdcDataSource from '../../DataSource/JsdcDataSource'
import JsdcEntity from '../../Entity/JsdcEntity'
import Strategy from './Strategy'

const ExtrudeHeightKeywordsProperties = [
  'levels', 'height'
]

class GeoJSONStrategy extends Strategy {
  public async exe () {
    const { url, name } = this.asset
    const isBuilding = this.asset.cache.options.isBuilding
    const geojsonDataSource = new GeoJsonDataSource(name)
    await geojsonDataSource.load(url, {
      fill: Color.WHITE,
      clampToGround: true,
      strokeWidth: 0,
      stroke: Color.WHITE
    })
    const entities = geojsonDataSource.entities.values
    for (const entity of entities) {
      if (isBuilding && entity.polygon) {
        this.extrudePolygonHeight(entity.polygon, 10)
      }
      if (entity.properties) {
        this.handleProperties(entity.properties, entity)
      }
      // const propNames = entity.properties?.propertyNames
    }
    const dataSource = this.convertToJsdcDataSource(geojsonDataSource, this.asset)
    return this.viewer.dataSources.add(dataSource)
  }

  private convertToJsdcDataSource (dataSource: GeoJsonDataSource, asset: Asset) {
    const newDataSource = new JsdcDataSource({ asset })
    dataSource.entities.values.forEach(entity => {
      const position = entity.position?.getValue({} as any) as Cartesian3
      const newEntity = new JsdcEntity({
        asset: new Asset({ id: UID.v4(), name: asset.name + '_' + UID.short(), type: '' }),
        type: 'controlPoint',
        name: entity.name,
        properties: entity.properties,
        position: position,
        point: entity.point?.clone(),
        polygon: entity.polygon?.clone(),
        polyline: entity.polyline?.clone()
      })
      newDataSource.entities.add(newEntity)
    })
    return newDataSource
  }

  private handleProperties (props: PropertyBag, entity: Entity) {
    const property = props.getValue(this.viewer.clock.currentTime)
    const propNames = props.propertyNames
    if (this.hasHeightProp(propNames)) {
      const height = this.getHeightValue(property, propNames)
      if (entity.polygon) {
        this.extrudePolygonHeight(entity.polygon, height)
      }
    }
  }

  private hasHeightProp (props: string[]) {
    for (const keyword of ExtrudeHeightKeywordsProperties) {
      if (props.join().includes(keyword)) {
        return true
      }
    }
    return false
  }

  private getHeightValue (props: any, propNames: string[]): number {
    for (const propsName of propNames) {
      // console.log(props, propsName, props[propsName])
      const propsValue = props[propsName]
      let value: number = random(3, 10)
      if (propsName.includes('level')) {
        value = Number(propsValue) * 3
      } else if (propsName.includes('height')) {
        value = Number(propsValue)
      } else {
        return isNaN(value) ? random(3, 10) : value <= 3 ? random(3, 10) : value
      }
    }
    console.warn('do something to propsName.includes("blablabla") and return height value')
    return random(3, 10)
  }

  private extrudePolygonHeight (polygon: PolygonGraphics, height: number) {
    polygon.heightReference = new ConstantProperty(HeightReference.CLAMP_TO_GROUND)
    polygon.extrudedHeight = new ConstantProperty(height)
    polygon.extrudedHeightReference = new ConstantProperty(HeightReference.RELATIVE_TO_GROUND)
  }
}

export default GeoJSONStrategy
