/* eslint-disable */
import JsdcDataSource from '../DataSource/JsdcDataSource'
import JsdcEntity from '../Entity/JsdcEntity'
import Jsdc3DTileset from '../ThreeDTileset/Jsdc3DTileset'
import CustomEvent from '../utils/CustomEvent'
import { AssociativeArray } from 'cesium'
import Strategy from '../AssetLoader/strategy/Strategy'
import Viewer from 'cesium/Source/Widgets/Viewer/Viewer'

export type ValidLayerType = JsdcEntity | JsdcDataSource | Jsdc3DTileset | Strategy

export type LayerAddEvent = ValidLayerType
export type LayerRemoveEvent = ValidLayerType
export type LayerUpdateEvent = {
  type: 'add' | 'remove',
  layer: ValidLayerType
}
export type LayerToggleShowEvent = {
  show: boolean,
  layer: ValidLayerType
}

class LayerController {
  public asyncViewer: Promise<Viewer>
  private _layers: AssociativeArray = new AssociativeArray()
  public addEvent: CustomEvent<LayerAddEvent> = new CustomEvent()
  public removeEvent: CustomEvent<LayerRemoveEvent> = new CustomEvent()
  public updateEvent: CustomEvent<LayerUpdateEvent> = new CustomEvent()
  public toggleShowEvent: CustomEvent<LayerToggleShowEvent> = new CustomEvent()

  constructor(asyncViewer: Promise<Viewer>) {
    this.asyncViewer = asyncViewer
  }

  public async flyTo (id: string | number) {
    const ctrlItem = this.getById(id)
    const viewer = await this.asyncViewer
    if (ctrlItem) {
      if (ctrlItem instanceof Strategy && ctrlItem.instance) {
        viewer.flyTo(ctrlItem.instance as any)
      } else if (!(ctrlItem instanceof Strategy)) {
        viewer.flyTo(ctrlItem)
      }
    }
  }

  public add (layer: ValidLayerType) {
    if (!(layer instanceof Strategy)) {
      this._layers.set(layer._asset.id, layer)
      this.addEvent.raiseEvent(layer)
      this.updateEvent.raiseEvent({ type: 'add', layer })
    }
  }

  public addLazy<T> (strategy: Strategy<T>) { // because execution of loader strategy needs to be delay, so I use strategy as arg to be executed in future
    this._layers.set(strategy.asset.id, strategy)
    // @ts-ignore
    this.addEvent.raiseEvent(strategy)
    // @ts-ignore
    this.updateEvent.raiseEvent({ type: 'add', layer: strategy })
  }

  public removeFromControl (id: string | number) {
    this._layers.remove(id)
  }

  public async removeFromViewer (id: string | number) {
    const viewer = await this.asyncViewer
    const layer = this.getById(id)
    let target: any = layer
    if (layer) {
      if (layer instanceof Strategy) {
        target = layer.instance
        layer.executed = false
        layer.instance = undefined
      }
      viewer.entities.remove(target)
      viewer.dataSources.remove(target)
      viewer.scene.primitives.remove(target)
      viewer.imageryLayers.remove(target)
    }
  }

  public remove (id: string | number) {
    this.removeFromViewer(id)
    this.removeFromControl(id)
  }

  public isShow (id: string | number) {
    const ctrlItem = this.getById(id)
    if (ctrlItem) {
      if (ctrlItem instanceof Strategy) {
        return ctrlItem.instance !== undefined
      } else if (!(ctrlItem instanceof Strategy)) {
        return ctrlItem.show
      }
    }
    return false
  }

  public async setToggleShow (id: string | number) {
    const val = !this.isShow(id)
    await this.setShow(id, val)
  }

  public async setShow (id: string | number, show: boolean) {
    if (show) {
      this._show(id)
    } else {
      await this._hide(id)
    }
  }

  private _show (id: string | number) {
    const ctrlItem = this.getById(id)
    if (ctrlItem) {
      if (ctrlItem instanceof Strategy && ctrlItem.instance === undefined) {
        ctrlItem.exe()
      } else if (!(ctrlItem instanceof Strategy)) {
        ctrlItem.show = true
      }
      this.toggleShowEvent.raiseEvent({ show: true, layer: ctrlItem })
    }
  }

  private async _hide (id: string | number) {
    const ctrlItem = this.getById(id)
    if (ctrlItem) {
      if (ctrlItem instanceof Strategy && ctrlItem.instance !== undefined) {
        this.removeFromViewer(ctrlItem.asset.id)
      } else if (!(ctrlItem instanceof Strategy)) {
        ctrlItem.show = false
      }
      this.toggleShowEvent.raiseEvent({ show: false, layer: ctrlItem })
    }
  }

  public listAll () {
    return this._layers.values.map((layer: ValidLayerType) => layer instanceof Strategy ? layer.asset : layer._asset)
  }

  public getById (id: string | number) {
    this._layers.get(id)
    const target = this._layers.get(id)
    if (target) {
      return target as ValidLayerType
    }
    console.warn(`id: ${id} not found`)
  }

  public getByName (name: string) {
    const target = this._layers.values.find((layer: ValidLayerType) => layer instanceof Strategy ? layer.asset.name : layer._asset.name)
    if (target) {
      return target as ValidLayerType
    }
    console.warn(`name: ${name} not found`)
  }

  public getByNames (name: string) {
    return this._layers.values.filter((layer: ValidLayerType) => layer instanceof Strategy ? layer.asset.name === name : layer._asset.name === name) as ValidLayerType[]
  }
}

export default LayerController
