import { Viewer } from 'cesium'
// import { isUndefined } from 'lodash'

export type Task = (viewer: Viewer) => void

class ViewerTask {
    viewer: Viewer | undefined
    private stack: Task[]
    constructor (viewer?: Viewer) {
      this.viewer = viewer
      this.stack = []
    }

    setViewer (viewer: Viewer) {
      if (this.viewer !== undefined) {
        console.warn('viewer already exist, setViewer will be ignore')
        return
      }
      this.viewer = viewer
      this.runTasks()
    }

    isViewerReady () {
      return this.viewer !== undefined
    }

    addTask (task: Task) {
      if (this.viewer !== undefined) {
        task(this.viewer)
      } else {
        this.stack.push(task)
      }
    }

    runTasks () {
      if (this.viewer !== undefined) {
        while (this.stack.length > 0) {
          const task = this.stack.pop()
          if (task) {
            task(this.viewer)
          }
        }
      }
    }
}

export default ViewerTask
