/* eslint-disable */
import classNames from 'classnames'
import './index.scss'
import { ExpandType } from '../Expand'
import { useContext } from 'react'
import { PanelContext } from '../../../context'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export interface IExpandPanel {
  children: React.ReactNode
  expandType: ExpandType
}

const ExpandPanel = ({
  children,
  expandType
}: IExpandPanel) => {
  const states = useContext(PanelContext)
  if (!states) return <></>
  const { selectedExpand, onExpandClick } = states

  return (
    <div className={classNames({
      'ExpandPanel': true,
      'show': selectedExpand === expandType,
      'hide': selectedExpand !== expandType
    })}>
      {children}
      <IconButton color='error' component="label"
        sx={{ position: 'absolute', top: '-10px', right: '-10px' }}
        onClick={() => {
          onExpandClick('close')
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
}

export default ExpandPanel
