import React from 'react'
import { JsdcContext } from './components/JsdcViewer'
import JSDC from './core/JSDC'
import JsdcMap from './pages/JsdcMap'

const App = () => {
  (window as any).JSDC = JSDC
  return (
    <JsdcContext.Provider value={JSDC}>
      <JsdcMap />
    </JsdcContext.Provider>
  )
}

export default App
