/* eslint-disable */
import * as Cesium from 'cesium'

export const Cartesian3ToDegree = (position: Cesium.Cartesian3 | undefined) => {
  if (!position) return
  const cartographic = Cesium.Cartographic.fromCartesian(position)
  const lat = Cesium.Math.toDegrees(cartographic.latitude)
  const lon = Cesium.Math.toDegrees(cartographic.longitude)
  return [lon, lat]
}
