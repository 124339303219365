/* eslint-disable */
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export interface ITreeAttribute {
  OBJECTID: number
  New_ID: number
  ID: number
  "名稱": string
  "編號": string
  "X座標": number
  "Y座標": number
  "實際樹高": number | null
  "樹高_LiDAR": number
  "F50坡度": number
  "F20坡度": number
  "海拔_DEM_": number
  "地點": string
  "產製年分": number
  "備註": string | null
  Filename: string
  F3D_Animation_LINK: string
  longitude: number
  latitude: number
  COUNTYNAME: string
  TOWNNAME: string
}

export interface IAttributeTable {
  row: ITreeAttribute | undefined
  sharedLink: string
}

const AttributeTable = ({
  row, sharedLink
}: IAttributeTable) => {

  if (!row) return <p>123</p>
  return (
    // <TableContainer component={Paper}>
    <Table>
      <TableBody>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'New_ID'}
          </TableCell>
          <TableCell align="right">
            {row.New_ID}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'ID'}
          </TableCell>
          <TableCell align="right">
            {row.ID}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'名稱'}
          </TableCell>
          <TableCell align="right">
            {row['名稱']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'實際樹高'}
          </TableCell>
          <TableCell align="right">
            {row['實際樹高']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'樹高_LiDAR'}
          </TableCell>
          <TableCell align="right">
            {row['樹高_LiDAR']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'50坡度'}
          </TableCell>
          <TableCell align="right">
            {row['F50坡度']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'20坡度'}
          </TableCell>
          <TableCell align="right">
            {row['F20坡度']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'海拔_DEM'}
          </TableCell>
          <TableCell align="right">
            {row['海拔_DEM_']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'地點'}
          </TableCell>
          <TableCell align="right">
            {row['地點']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'產製年分'}
          </TableCell>
          <TableCell align="right">
            {row['產製年分']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'備註'}
          </TableCell>
          <TableCell align="right">
            {row['備註']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'Filename'}
          </TableCell>
          <TableCell align="right">
            {row['Filename']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'X座標'}
          </TableCell>
          <TableCell align="right">
            {row['X座標']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'Y座標'}
          </TableCell>
          <TableCell align="right">
            {row['Y座標']}
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'F3D_Animation_LINK'}
          </TableCell>
          <TableCell align="right">
            <CopyToClipboard
              text={row['F3D_Animation_LINK']}
            >
              <Button
                variant='contained'
                fullWidth
              >複製鏈結</Button>
            </CopyToClipboard>
          </TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {'分享連結'}
          </TableCell>
          <TableCell align="right">
            <CopyToClipboard
              text={`${window.location}?id=${row['OBJECTID']}${sharedLink}`}
            >
              <Button
                variant='contained'
                fullWidth
              >複製鏈結</Button>
            </CopyToClipboard>
            {/* {`${window.location}?id=${row['編號']}${sharedLink}`} */}
          </TableCell>
        </TableRow>

      </TableBody>
    </Table>
    // </TableContainer>
  )
}

export default AttributeTable
