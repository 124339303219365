import { Event } from 'cesium'

class CustomEvent<P = any> extends Event {
  addEventListener (listener: (params: P) => void, scope?: P): Event.RemoveCallback {
    return super.addEventListener(listener, scope)
  }

  removeEventListener (listener: (params: P) => void, scope?: P): boolean {
    return super.removeEventListener(listener, scope)
  }

  raiseEvent (arg?: P): void {
    super.raiseEvent(arg)
  }
}

export default CustomEvent
