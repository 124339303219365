import React, { useContext, useEffect, useState } from 'react'
import { JsdcContext } from '../../components/JsdcViewer'
import './PopupInfobox.scss'
import classNames from 'classnames'
import InfoboxHeader from './InfoboxHeader'
import Icon from '../../jsdc-ui/Icons'
import { ThreeDTileFeaturePopup } from '../../core/Controller/InfoBoxController'
import { BasicTable } from '../../jsdc-ui/components/Table'

export interface IPopupInfoboxProps {
}

const PopupInfobox = () => {
  const [contentComp, setcontentComp] = useState<React.ReactNode>()
  const [tracking] = useState(false)
  const [title] = useState<string>('')
  const [hide, setHide] = useState(true)
  const JSDC = useContext(JsdcContext)

  const handleTrackEntity = () => {
    JSDC.viewerTask.addTask(viewer => {
      viewer.trackedEntity = viewer.trackedEntity === undefined ? viewer.selectedEntity : undefined
    })
  }
  const handleUnFocusEntity = () => {
    JSDC.viewerTask.addTask(viewer => {
      viewer.selectedEntity = undefined
    })
    setcontentComp(undefined)
  }

  const handleSelectEntityChange = (e: any) => {
    if (e === undefined) {
      setHide(true)
      setcontentComp(undefined)
    }
  }

  const createThreeDTileTable = (props: { [k: string]: string }) => {
    const component = (
      <BasicTable body={Object.keys(props).map(key => [key, props[key]])} />
    )
    setcontentComp(component)
    setHide(false)
  }

  const handleThreeDTilePopup = ({ props }: ThreeDTileFeaturePopup) => {
    createThreeDTileTable(props)
  }

  const init = async () => {
    const viewer = await JSDC.asyncViewer
    JSDC.InfoBoxController?.start()
    JSDC.InfoBoxController?.ThreeDTileFeaturePopupEvent.addEventListener(handleThreeDTilePopup)
    viewer.selectedEntityChanged.addEventListener(handleSelectEntityChange)
  }

  useEffect(() => {
    init()
    return () => {
      JSDC.InfoBoxController?.stop()
    }
  }, [])

  return (
    <div className={classNames({
      'jsdc-popupInfobox': true,
      hide
    })}>
      <div className={classNames({
        'jsdc-hinter': true,
        hide
      })}
      onClick={() => setHide(prev => !prev)}>
        <Icon type='collaps_arrow_right' size='larg' light/>
      </div>
      <div className="jsdc-popupInfobox-containter">
        <InfoboxHeader tracking={tracking} text={title} onToggleTrack={handleTrackEntity} onClose={handleUnFocusEntity}/>
        <div className="jsdc-popupInfobox-content">
          {contentComp}
        </div>
      </div>
    </div>
  )
}

export default PopupInfobox
