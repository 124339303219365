import React from 'react'
import IconButton from '../../jsdc-ui/components/Button/IconButton'
import { Button } from '../../jsdc-ui/components/Button'
import './InfoboxHeader.scss'
export interface IInfoboxHeaderProps {
  text: string
  tracking: boolean
  onToggleTrack: () => void
  onClose: () => void
}

const InfoboxHeader = ({
  text,
  tracking,
  onToggleTrack,
  onClose
}: IInfoboxHeaderProps) => {
  const getIconType = () => {
    return tracking ? 'camera_off' : 'camera'
  }
  return (
    <div className="jsdc-infobox-header">
      <IconButton type={getIconType()} varient='flat' onClick={onToggleTrack}/>
      <div className="label">{text}</div>
      <Button varient='flat' onClick={onClose}>✕</Button>
    </div>
  )
}

export default InfoboxHeader
