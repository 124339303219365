/* eslint-disable */
import './index.scss'

export interface ILiDAR {
  src: string
  onClick: (src: string) => void
}

const LiDAR = ({
  src,
  onClick
}: ILiDAR) => {
  return (
    <div className='LiDAR' onClick={() => { onClick(src) }}>
      <img src={src} width='100%' height='100%' />
    </div>
  )
}

export default LiDAR
