/* eslint-disable */
import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export interface IMultiChipSelect {
  placeholder: string
  towns: string[]
  townsSet: string[]
  isUrbanUsageFiltered: boolean
  isUrbanUsageFosced: boolean
  onChange: (value: string[]) => void
  onRemove: (event: React.MouseEvent, value: string) => void
}

export default function MultiChipSelect (props: IMultiChipSelect) {
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (typeof value === 'string') return
    const newValue = value.map(function (x) {
      return x;
    })
    props.onChange(newValue)
  }

  React.useEffect(() => {
    if (props.towns === null || props.towns === undefined) return
    const newValue = props.towns.map(function (x) {
      return x.toString()
    })
    setPersonName(newValue)
  }, [props.towns])

  return (
    <FormControl size='small' fullWidth>
      <InputLabel id="land-use">{props.placeholder}</InputLabel>
      <Select
        labelId="land-use"
        id="land-use-select"
        multiple
        value={personName}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {
              selected.map((value) => (
                <Chip key={value} label={value}
                  sx={{
                    height: '23px'
                  }}
                  onDelete={(e) => props.onRemove(e, value)}
                  onMouseDown={(event) => {
                    event.stopPropagation()
                  }}
                  clickable
                />
              ))
            }
          </Box>
        )}
        MenuProps={MenuProps}
        disabled={!props.isUrbanUsageFiltered}
      >
        {
          props.townsSet.map((town, index) => {
            return <MenuItem
              key={index}
              value={town}
            >
              {town}
            </MenuItem>
          })
        }
      </Select>
    </FormControl>
  )
}