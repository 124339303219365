import { Clock, Event, Viewer } from 'cesium'
import * as Cesium from 'cesium'

class TimeController {
    viewer: Viewer
    onTickEvent: Event = new Event()
    onStopEvent: Event = new Event()
    constructor (viewer: Viewer) {
      this.viewer = viewer
      this.handleTickEventTrigger = this.handleTickEventTrigger.bind(this)
      this.handleStopEventTrigger = this.handleStopEventTrigger.bind(this)

      this.clock.onTick.addEventListener(this.handleTickEventTrigger)
      this.clock.onStop.addEventListener(this.handleStopEventTrigger)
    }

    public get clock () : Clock {
      return this.viewer.clock
    }

    public get multiplier () : number {
      return this.clock.multiplier
    }

    public set multiplier (v : number) {
      this.viewer.clock.multiplier = v
    }

    public get timeLable () : string {
      const date = new Date(this.clock.currentTime.toString())
      return date.toLocaleString()
    }

    private toYYYYMMDD (date: Date): string {
      const mm = date.getMonth() + 1
      const dd = date.getDate()
      const dateString = [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
      ].join('')
      return dateString.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
    }

    private toHHMMSS (date: Date): string {
      return date.toTimeString().split(' ')[0]
    }

    public get dateTimeLabel (): string[] {
      // const date = new Date(this.clock.currentTime.toString())
      const date: Date = Cesium.JulianDate.toDate(this.clock.currentTime)
      const dateLabel = this.toYYYYMMDD(date)
      const timeLabel = this.toHHMMSS(date)
      return [
        dateLabel,
        timeLabel
      ]
    }

    public get playing () : boolean {
      return this.clock.shouldAnimate
    }

    public set playing (v : boolean) {
      this.viewer.clock.shouldAnimate = v
    }

    destroy () {
      this.clock.onTick.removeEventListener(this.handleTickEventTrigger)
      this.clock.onStop.removeEventListener(this.handleStopEventTrigger)
    }

    handleTickEventTrigger () {
      this.onTickEvent.raiseEvent(this)
    }

    handleStopEventTrigger () {
      this.onStopEvent.raiseEvent()
    }

    setPlayDirection (variant: 'forward' | 'backward') {
      switch (variant) {
        case 'forward':
          this.clock.multiplier = Math.abs(this.multiplier)
          break
        case 'backward':
          this.clock.multiplier = Math.abs(this.multiplier) * -1
          break
        default:
          throw new Error(`variant: ${variant}, not defined`)
      }
    }
}

export default TimeController
