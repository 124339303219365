import { Entity } from 'cesium'
import { Asset } from '../Asset'
import { JsdcEntityType } from '../JSDC'

export interface jsdcEntityConstructorOptions extends Entity.ConstructorOptions {
  type: JsdcEntityType
  asset: Asset
}

class JsdcEntity extends Entity {
  type: JsdcEntityType
  _asset: Asset
  constructor (params: jsdcEntityConstructorOptions) {
    super(params)
    this.type = params.type
    this._asset = params.asset
  }
}

export default JsdcEntity
