import { get } from 'lodash'
import { AssetApiResponseType } from '../../api/modules/assets'
import { UID } from '../../utils'
import { AssetType } from './AssetType'
import IAsset from './IAsset'

class Asset implements IAsset {
  cache: any
  id: string
  name: string
  group: string | undefined | null
  type: AssetType
  constructor (params: AssetApiResponseType) {
    this.cache = params
    this.id = params.id || UID.short()
    this.name = params.name || this.id
    this.type = params.type as AssetType
    this.group = params.group || '其他'
  }

  /** override this getter to get the right path of url */
  public get url (): string {
    return get(this.cache, 'options.tilesetPath')
  }

  public isType (type: AssetType) {
    return this.type === type
  }
}

export default Asset
