import React from 'react'
import './index.scss'

export interface IToolbarProps {
  extendsItems?: Array<React.ReactNode>
}

const Toolbar = ({
  extendsItems = []
}: IToolbarProps) => {
  return (
    <div className="jsdc-Toolbar">
      {
        extendsItems.map(item => item)
      }
    </div>
  )
}

export default Toolbar
