import { createContext } from 'react'
import { SpatialQueryType } from '../hooks'
import { ExpandType } from '../bigTreeCompnt/ExpandContainer/Expand'
import { Viewer } from 'cesium'

export interface IPanelContext {
  heightRange: number[]
  bufferRadius: number
  county: string
  towns: string[]
  treeId: string
  selectedExpand: ExpandType
  resultTreeIds: string[]
  queryType: SpatialQueryType
  isHeightRangeIncluded: boolean
  isTreeIdIncluded: boolean
  onHeightChange: (newValue: number | number[]) => void
  onBufferChange: (value: number) => void
  onCountyChange: (value: string) => void
  onTownsChange: (value: string[]) => void
  onTreeIdChange: (value: string) => void
  onExpandClick: (expandType: ExpandType) => void
  onResultTreeIdsChange: (value: string[]) => void
  onQuery: (value: SpatialQueryType, viewer: Viewer) => void
  onQueryTypeChange: (queryType: SpatialQueryType) => void
  onTreeIdIncluded: (value: boolean) => void
  onHeightRangeInclude: (value: boolean) => void
}

export const PanelContext = createContext<IPanelContext | undefined>(undefined)
