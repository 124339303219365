/* eslint-disable */
import React, { useContext } from 'react'
import classNames from 'classnames'
import { Tooltip } from '@mui/material'
import './index.scss'
import { PanelContext } from '../../../context'

export type ExpandType = 'query' | 'result' | 'upload' | 'close'

export interface IExpand {
  content: string
  iconPath: string
  expandType: ExpandType
}

const Expand = ({
  content,
  iconPath,
  expandType
}: IExpand) => {
  const states = useContext(PanelContext)
  if (!states) return <></>
  const { selectedExpand, onExpandClick } = states
  return (
    <Tooltip title={content} placement='right-start'>
      <div className={classNames({
        'Expand': true,
        'selected': expandType === selectedExpand
      })}
        onClick={() => { onExpandClick(expandType) }}
      >
        <img src={iconPath} />
      </div>
    </Tooltip>
  )
}

export default Expand