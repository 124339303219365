
const IconAssetMap = {
  search: 'search_black_24dp.svg',
  menu: 'menu_black_24dp.svg',
  camera: 'videocam_black_24dp.svg',
  camera_off: 'videocam_off_black_24dp.svg',
  hinter: 'ICONS_animation_hinter.svg',
  close: 'clear_black_24dp.svg',
  history: 'history_black_24dp.svg',
  link: 'link_black_24dp.svg',
  checkList: 'checklist_black_24dp.svg',
  layers: 'layers_black_24dp.svg',
  info: 'info_black_24dp.svg',
  focus: 'center_focus_weak_black_24dp.svg',
  collaps_arrow_right: 'last_page_black_24dp.svg',
  expand_more: 'expand_more_black_24dp.svg',
  expand_less: 'expand_less_black_24dp.svg'
}

export type IconAssetKeyType = keyof typeof IconAssetMap

export default IconAssetMap
