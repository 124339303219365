import { Viewer } from 'cesium'
import React, { useEffect, ReactNode, useContext } from 'react'
import 'cesium/Source/Widgets/widgets.css'
import './custom-style/Timeline.css'
import { JsdcContext } from '.'
// import { TimeControlPanel } from './TimeControlPanel'
import { PopupInfobox } from '../../containers/PopupInfobox'
import Toolbar from '../Toolbar'
import './CesiumContainer.scss'

export type CesiumContainerProps = {
  id?: string,
  disabledTimeline?: boolean,
  disabledPopupInfobox?: boolean,
  disabledToolbar?: boolean,
  toolbarItems?: Array<React.ReactNode>
  created?: (viewer: Viewer) => void,
  children?: ReactNode,
}
const CesiumContainer = (props: CesiumContainerProps) => {
  const {
    id = 'jsdc-container',
    toolbarItems = [],
    disabledPopupInfobox = false,
    disabledToolbar = false,
    created
  } = props

  const context = useContext(JsdcContext)

  useEffect(() => {
    (window as any).CESIUM_BASE_URL = window.location.origin + process.env.PUBLIC_URL
    const viewer = context.createViewer(id)
    if (created) {
      created(viewer)
    }
  }, [])

  return (
    <div
      className='jsdc-CesiumContainer'
      data-testid='jsdc-container'
      id={id}
      style={{ width: '100%', height: '100%' }}>
      {disabledToolbar ? '' : <Toolbar extendsItems={toolbarItems} />}
      {/* { disabledTimeline ? '' : <TimeControlPanel /> } */}
      {disabledPopupInfobox ? '' : <PopupInfobox />}
      {props.children}
    </div>
  )
}

export default CesiumContainer
