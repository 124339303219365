import { Cartesian3, Cesium3DTileset, HeadingPitchRoll, Matrix4, Math } from 'cesium'
import { Asset } from '../../Asset'
import Strategy from './Strategy'
import { get } from 'lodash'
import TransformEditor from '../../Transform/TransformEditor'

const defaultLocation = {
  position: { lng: 0, lat: 0, height: 0 },
  rotation: { heading: 0, pitch: 0, roll: 0 },
  scale: [1, 1, 1]
}

class ThreeDTILEStrategy extends Strategy {
  public async exe () {
    const { position, rotation, scale } = this.extractAssetData(this.asset)
    console.log({ position, rotation, scale })
    const tileset = new Cesium3DTileset({
      url: this.asset.url
    })
    await tileset.readyPromise
    this.viewer.scene.primitives.add(tileset)
    this.setLocation(tileset, { position, rotation, scale })
    const transformEditor = this.setLocation(tileset, { position, rotation, scale });
    (tileset as any).transformEditor = transformEditor
    return tileset
  }

  private extractAssetData (asset: Asset) {
    const version = get(asset.cache, 'fileVersion', '') as string

    const position = get(
      asset.cache,
      `options.location["${version}"].position`,
      get(asset.cache, 'options.location.position', defaultLocation.position)
    ) as typeof defaultLocation['position']

    const rotation = get(
      asset.cache,
      `options.location["${version}"].rotation`,
      get(asset.cache, 'options.location.rotation', defaultLocation.rotation)
    ) as typeof defaultLocation['rotation']

    const scale = get(
      asset.cache,
      `options.location["${version}"].scale`,
      get(asset.cache, 'options.location.scale', defaultLocation.scale)
    ) as typeof defaultLocation['scale']

    return { version, position, rotation, scale }
  }

  private setLocation (
    tileset: Cesium3DTileset,
    { position, rotation, scale }: {
      position: typeof defaultLocation['position'],
      rotation: typeof defaultLocation['rotation'],
      scale: typeof defaultLocation['scale']
    }) {
    const viewer = this.viewer
    const rootTransform = tileset.root.transform
    const modelMatrix = tileset.modelMatrix
    Matrix4.clone(rootTransform, modelMatrix)
    Matrix4.clone(Matrix4.IDENTITY, rootTransform)

    const transformEditor = new TransformEditor({
      container: viewer.container,
      scene: viewer.scene,
      transform: tileset.modelMatrix,
      boundingSphere: tileset.boundingSphere,
      pixelSize: 100,
      maximumSizeInMeters: Infinity
    })
    transformEditor._viewModel.activate()
    transformEditor._viewModel.position = Cartesian3.fromDegrees(
      Number(position.lng),
      Number(position.lat),
      Number(position.height)
    )
    transformEditor._viewModel.headingPitchRoll = new HeadingPitchRoll(
      Math.toRadians(Number(rotation.heading)),
      Math.toRadians(Number(rotation.pitch)),
      Math.toRadians(Number(rotation.roll))
    )
    transformEditor._viewModel.scale = new Cartesian3(
      Number(scale[0]),
      Number(scale[1]),
      Number(scale[2]))
    transformEditor._viewModel.deactivate()
    return transformEditor
  }
}

export default ThreeDTILEStrategy
