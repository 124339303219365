import { Viewer } from 'cesium'
import { Asset } from '../../Asset'

export default class Strategy<T = {}> {
  viewer: Viewer
  asset: Asset
  executed: boolean = false
  instance: T | undefined
  constructor (viewer: Viewer, asset: Asset) {
    this.viewer = viewer
    this.asset = asset
  }

  async exe () {
    if (this.executed) {
      console.warn(`asset: ${this.asset} has been executed`)
    }
    this.executed = true
    return {} as T
  }
}
