/* eslint-disable */
import './index.scss'
import classNames from 'classnames'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useState } from 'react'
import AttributeTable, { ITreeAttribute } from './AttributeTable'
import LiDAR from './LiDAR'
// import YouTube from 'react-youtube'
import React from 'react'
import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from "react-player/types/lib";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;


export interface IPopup {
  open: boolean
  // transform: React.CSSProperties
  row: ITreeAttribute | undefined
  liDARImage: string | undefined
  youtubeLink: string
  sharedLink: string
  onPhotoClick: (src: string) => void
  onClose: () => void
}

const Popup = ({
  open,
  // transform,
  row,
  liDARImage,
  youtubeLink,
  sharedLink,
  onPhotoClick,
  onClose
}: IPopup) => {
  const theme = useTheme()
  const isBreakPointHit = useMediaQuery(theme.breakpoints.up('sm'))
  const [value, setValue] = useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <div
      className={classNames({
        'Popup': true,
        // 'show': open
        'show': open,
        'hide': !open
      })}
    // style={isBreakPointHit ? transform : undefined}
    >

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="基本資訊" value="1" />
            <Tab label="LiDAR" value="2" />
            <Tab label="線上影片" value="3" />
          </TabList>
        </Box>

        <TabPanel value="1" sx={{ padding: '0px', overflowY: 'auto' }} className='tabPanel'>
          <AttributeTable row={row} sharedLink={sharedLink} />
        </TabPanel>

        <TabPanel value="2">
          {
            liDARImage
              ? <LiDAR src={liDARImage} onClick={onPhotoClick} />
              : <p>無圖片</p>
          }

        </TabPanel>

        <TabPanel value="3">
          {/* <YouTube
            videoId={youtubeLink}
            // className='yt'
            opts={{
              width: '100%',
              height: '100%'
            }}
          /> */}

          <ReactPlayer
            url={youtubeLink}
            width='100%'
            height='100%'
          />
        </TabPanel>
      </TabContext>

      <div className='closeBtn' onClick={onClose}>
        ✖
      </div>

    </div>
  )
}

export default Popup
