import {
  defined,
  Event,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Viewer,
  Cartesian3,
  Entity,
  ModelMesh,
  ModelNode,
  Primitive,
  Cesium3DTileFeature
} from 'cesium'
import CustomEvent from '../utils/CustomEvent'

export type ScenePickType = {
  id?: Entity,
  mesh?: ModelMesh,
  node?: ModelNode,
  primitive?: Primitive
}

export type PickEntityType = 'TruckEntity'

export interface PopupEvent {
  pickEntityType: PickEntityType,
}

export interface ThreeDTileFeaturePopup {
  feature: Cesium3DTileFeature
  props: any
}

export default class InfoBoxController {
  viewer: Viewer
  ScreenSpaceEventHandler: ScreenSpaceEventHandler
  leftClickEvent: Event = new Event()
  ThreeDTileFeaturePopupEvent = new CustomEvent<ThreeDTileFeaturePopup>()
  EmptyPopupEvent = new CustomEvent()
  open: boolean = false
  title: string = ''
  pickedPosition: Cartesian3 | undefined
  constructor (viewer: Viewer) {
    this.viewer = viewer
    this.ScreenSpaceEventHandler = new ScreenSpaceEventHandler(viewer.canvas)
    this.handleLeftClick = this.handleLeftClick.bind(this)
    this.viewer.infoBox.destroy()
  }

  init () {
    this.viewer.infoBox.destroy()
  }

  start () {
    this.ScreenSpaceEventHandler.setInputAction(this.handleLeftClick, ScreenSpaceEventType.LEFT_CLICK)
  }

  stop () {
    this.ScreenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
  }

  handleLeftClick (movement: any) {
    this.pickedPosition = this.viewer.camera.pickEllipsoid(movement.position, this.viewer.scene.globe.ellipsoid)
    const viewer = this.viewer
    const pickedFeature = (viewer.scene.pick(movement.position)) as ScenePickType

    if (!defined(pickedFeature)) {
      this.EmptyPopupEvent.raiseEvent()
    }
    if (pickedFeature instanceof Cesium3DTileFeature) {
      const createProps = (feature: Cesium3DTileFeature) => {
        const result: any = {}
        const names = feature.getPropertyNames()
        names.forEach(name => {
          result[name] = feature.getProperty(name)
        })
        return result
      }
      this.ThreeDTileFeaturePopupEvent.raiseEvent({ feature: pickedFeature, props: createProps(pickedFeature) })
    }
  }
}
