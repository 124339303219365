import { Viewer } from 'cesium'
import { ThreeDTILEStrategy, GeoJSONStrategy } from '.'
import { Asset } from '../Asset'
import Strategy from './strategy/Strategy'

class AssetLoader {
  viewer: Viewer
  constructor (viewer: Viewer) {
    this.viewer = viewer
  }

  public load (asset: Asset) {
    switch (asset.type) {
      case '3DTILES':
        return new ThreeDTILEStrategy(this.viewer, asset).exe()
      case 'GeoJSON':
        return new GeoJSONStrategy(this.viewer, asset).exe()
      default:
        console.warn(`loader strategy of asset type: ${asset.type} hasn't been implemented yet`)
    }
  }

  public loadByStrategy<T extends Strategy<P>, P> (Strategy: T) {
    return Strategy.exe()
  }
}

export default AssetLoader
